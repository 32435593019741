
.footer {
    width: 100%;
    height: auto;
    position: relative;
    background-color: #04768a8e; /* Change color as needed */
    padding: 3rem 0;
  }
  
.footer .top {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
}

.footer-logo {
    width: 150px;
}

.footer-logo img {
    width: 100%;
}

.footer .top .logo-footer {
    width: 50%;
    height: auto;
}

.footer .top .logo-footer img {
    width: 50%;
}

.footerFlex {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
}


.logo-footer h2 {
    font-size: 1rem;
    font-family: 'El Messiri', sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    padding: 1rem 0;
    color: white;

}

.logo-footer p {
    text-align: left;
    color: white;
}

.footerFlexItem {
    width: 35%;
}

.footerFlexItem h1 {
    font-size: 1rem;
    font-family: 'El Messiri', sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    color: #ffd901;
    text-align: center;
}


.footer .top .logo-footer img {
    width: 100%;
    height: auto;
}

.footer .top .icon {
    font-size: 3rem;
    color: white;
    cursor: pointer;
    margin: auto;
}


.footerFlexItem p {
    margin: .5rem 0;
    color: white;
    text-align: center;
}



.certificationLogo {
    width: 100%;
    margin: 0 auto;
    padding: 1rem;
}

.certification h3 {
    font-size: .8rem;
    font-family: 'El Messiri', sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    color: white;
    text-align: center;
}

.certification p {
    text-align: center;
    color: white;
}


.certificationLogoImage {
    width: 300px;
    margin: auto;
}

.certificationLogoImage img{
    width: 100%;
}

  .copyright p {
    padding-bottom: 1rem;
    text-align: center;
    color: white;
}
  

@media screen and (max-width: 940px) {
    .certification {
        flex-wrap: wrap;
    }

    .certificationRight {
        width: 100%;
    }

    .certificationLeft {
        width: 100%;
    }
    
    .footer {
        height: 100%;
    }

    .footerFlex {
        flex-wrap: wrap;
    }
}


@media screen and (max-width: 600px) {
    .footerFlexItem {
        width: 100%;
    }

    .footer-logo {
        width: 150px;
    }

    .footer .top .logo-footer {
        width: 100%;
        height: auto;
    }
}

