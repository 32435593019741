/* src/components/Navbar.css */
.navbar {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: auto;
  position: relative;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.829);
  }
  
  .navbar .navbar-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .subArrow {
    margin: auto;
    align-items: center;
    margin-bottom: 15px;
  }

  .navbar-logo {
    width: 250px;
    z-index: 2;
  }
  
  .navbar-logo img {
    width: 100%;
    padding: 10px;
  }

  .navbar .mobile-icon {
    display: none;
  }
  
  .navbar .nav-menu {
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
  }
  
  .navbar .nav-item {
    height: 60px;
  }
  
  .navbar .nav-links {
    color:  var(--primary-color);
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
  }
  
  .navbar .dropdown {
    position: relative;
  }
  
  .navbar .dropdown-menu {
    display: none;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    margin: 0 auto;    
    background: rgb(172, 106, 7);
    list-style: none;
    padding: 0;
    width: max-content;
    border-radius: 2px;
    z-index: 99999;
  }
  
  .navbar .dropdown-menu .nav-links {
    display: block;
    padding: 10px 20px;
    color: white;
  }
  
  .navbar .dropdown-menu .nav-links:hover {
    background: #58a5cc;
  }
  
  .navbar .dropdown:hover .dropdown-menu {
    display: block;
  }

  @media screen and (max-width: 940px) {
    .navbar {
      flex-direction: column;
      height: auto;
    }

    .navbar .navbar-container {
      justify-content: space-between;
    }
  
    .navbar .mobile-icon {
      display: block;
      position: absolute;
      right: 20px;
      font-size: 1.8rem;
      cursor: pointer;
      color: var(--primary-color);
      z-index: 99999;
    }
  
    .navbar .nav-menu {
      position: absolute;
      flex-direction: column;
      width: 70%;
      height: 83.5vh;
      top: 0;
      left:-100%;
      justify-content: center;
      background: white;
      transition: 0.5s ease-in;
      border-bottom-right-radius: 5rem;
    }

    .navbar .dropdown-menu {
      background: #9b680a;
    }

  
    .navbar .nav-menu.active {
      left: 0;
    }
  
    .navbar .nav-item {
      width: 100%;
    }
  
    .navbar .nav-links {
      text-align: center;
      padding: 20px;
      width: 100%;
      display: table;
    }
  }

  @media screen and (max-width: 600px) {
    .navbar-logo {
      width: 200px;
    }

    .navbar .dropdown-menu {
      width: 90%;
    }
  }
  