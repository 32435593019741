.section3 {
    width: 100%;
    height: auto;
    position: relative;
    background-color: #04768a8e; /* Change color as needed */
  }
  
  .section3-content {
    padding: 2rem 1rem;
  }

  .section3Header {
    width: 100%;
    padding: 1rem;
  }

  .section3Header h1 {
    font-size: 1.5rem;
    color: rgb(245, 239, 239);
    font-family: "kinta";
    text-align: center;
    padding: .5rem;
    font-weight: normal;
    letter-spacing: 1px;
    line-height: 1.5rem;
  }


  .section3Header h2 {
    font-size: 1.2rem;
    color: rgb(245, 239, 239);
    font-family: 'roboto', sans-serif;
    text-align: center;
    padding: .5rem;
    font-weight: normal;
    letter-spacing: 1px;
    line-height: 1.5rem;
  }



.section3-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
    padding: 1rem;
}

.section3-flexItem {
    width: 30%;
    height: auto;
    border: 1px solid rgba(255, 255, 255, 0.336);
    border-radius: 5px;
}

.section3-flexItem img {
    width: 100%;
    border-radius: .2rem;
    border-radius: 5px;
}

.section3-flexItem h1 {
  font-size: 1rem;
  color: rgb(245, 239, 239);
  font-family: 'El Messiri', sans-serif;
  text-align: center;
  padding: .5rem 0;
  font-weight: normal;
  letter-spacing: 3px;
}

.section3-flexItem p {
  padding: .5rem;
  color: rgb(236, 226, 226);

}

.section3-flexItem {
    text-align: center;
}

.section3-flexItem button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    font-weight: 500;
    margin: 1rem;
}

.section3-flexItem button:hover {
  background-color: #04768a8e; /* Change color as needed */
  border: none;
}


@media  screen and (max-width: 940px) {
    
    .section3-flexItem {
        width: 90%;
    }

    .section3Header {
        width: 100%;
        padding: 1rem;
      }


    
}



