.sectionFour {
    width: 100%;
    height: auto;
    position: relative;
  }
  
  .sectionFour:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.08;
  }


.sectionFourContent {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding-top: 3rem;
}

.sectionFourRight {
    width: 100%;
    margin: auto;
    padding: 1rem;
}

.sectionFourRight h1 {
    font-size: 1rem;
    color: var(--primary-color);
    font-family: "kinta";
    text-align: left;
    padding: .5rem 0;
    font-weight: normal;
    letter-spacing: 3px;
  }
  
.sectionFourRight h2 {
    font-size: 2rem;
    text-align: left;
    padding: .5rem 0;
    letter-spacing: 3px;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.589);
    line-height: 2rem;
}

.sectionFourRight p {
    text-align: justify;
    padding: .5rem 0;
}

.sectionFourLeft {
    width: 100%;
    position: relative;
}


.sectionFourLeft {
    width: 100%;
    margin:  0 auto;
}

.sectionFourLeftImg {
    width: 100%;
    margin: auto;
}

.sectionFourLeftImg img {
    width: 100%;
    border-radius: 5px;
    display: block;
}



.sectionFourRightBtn button {
    color: white;
}



@media  screen and (max-width:940px) {

    .sectionFourContent {
        flex-wrap: wrap;
    }
    
    .sectionFourRight {
        width: 100%;
    }
    
    .sectionFourLeft {
        width: 100%;
    }

    .sectionFourRight h1 {
        text-align: center;
    }

    .sectionFourRight h2 {
        text-align: center;
    }

    .sectionFourRight h5 {
        text-align: center;
    }

    .sectionFourRight h2 {
        font-size: 2rem;
        line-height: 2.5rem;
    }



}


@media  screen and (max-width:600px) {
    .sectionFourRight h2 {
        font-size: 1.3rem;
        text-align: center;
        letter-spacing: 1px;
        line-height: 1.5rem;
    }
}

