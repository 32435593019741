:root {
  --primary-color: #3E7B99;
  --primary-dark: #141d35;
  --secondary-color: rgb(197, 136, 24);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


font-face {
  font-family: "kinta";
  src: local("kinta"),
   url("./assets/Kinta.ttf") format("truetype");
  font-weight: normal;
 }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  max-width: 1240px;
  margin: auto;
  height: 100%;
  position: relative;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

a:hover {
  color: rgb(218, 133, 76);
  text-decoration: none;
}



h1 {
  font-size: 25px;
  font-family: 'Roboto', sans-serif;
  color: var(--primary-color);

}

h2 {
  font-size: 25px;
  font-family: 'Roboto', sans-serif;
  color: var(--primary-color);
}


button {
  font-family: 'Roboto', sans-serif;
  padding: .8rem 3rem;
  background-color: var(--primary-color);
  border: none;
  color: #f8f8f8;
  font-size: 1.2rem;
  font-weight: 500;
  border-radius: 5px;
  cursor:pointer;

}

button:hover {
  background-color: #a0e6ce;
  color: var(--primary-color);
}

/* SUBMENU */

/* .sub__menus__arrows:hover .sub__menus{
  display: block;
}

.sub__menus__arrows__full:hover .sub__menus{
  display: block;
  z-index: 1000000;
}

.menu-item .sub__menus  a {
padding: 7px 0 0px 0;
margin: 0px 5px;
} */