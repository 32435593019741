.section-bg {
        width: 100%;
        height: auto;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.397);
}
      
      .section-bg:before {
        content: '';
        position: absolute;
        background: url('../../assets/section-bg2.webp') no-repeat center center/cover;
        height: 100%;
        width: 100%;
        z-index: -1;
        /* opacity: 0.6; */
}


.hero-container {
    display: flex;
    height: auto;
    justify-content: center;
}

.hero-text {
    width: 100%;
    margin: 20rem 0 0 0 ;
    padding: 0 1rem;
}

.hero-text h1 {
    font-size: 5rem;
    color: rgba(23, 102, 121, 0.877);
    font-family: "kinta";
    text-align: left;
    font-weight: normal;
    letter-spacing: 3px;
    line-height: 4rem;
    text-transform: uppercase;
}

.hero-text h2 {
    color: rgb(245, 148, 3);
    font-weight: normal;
    font-size: 1.3rem;
    font-family: 'Roboto', sans-serif;
    text-align: left;
    padding: .5rem 0;
}

.hero-text h3 {
  font-weight: normal;
  font-size: 1.5rem;
  padding: .5rem 0;
  color: rgba(23, 102, 121, 0.877);

}

.image-slider {
    width: 100%;
    position: relative;
    height: 90dvh;
}

.image-slider img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .image-slider img.active {
    opacity: 1;
    animation: fade-in 1s forwards;
  }
  
  .image-slider img.inactive {
    opacity: 0;
    animation: fade-out 1s forwards;
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  

/* Button */

.heroIcons {
    text-align: left;
    margin: 1rem 0;
  }
  
  .heroIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(172, 106, 7, 0.596); /* Change color as needed */
    color: #ffffff;
    font-size: 24px;
  }
  
  .heroIcons {
    display: flex;
    gap: 1rem;
  }

  .heroBtn button {
    background-color: rgb(31, 133, 192); /* Change color as needed */
    padding: .8rem 1.5rem;
    background-color: (31, 133, 192); /* Change color as needed */
    color: #ffffff;

  }
  

@media screen and (max-width: 940px) {
    .hero-container {
        flex-wrap: wrap-reverse;
    }

    .hero-text {
        width: 100%;
        margin: 0 auto;
    }

    .image-slider {
        width: 100%;
        position: relative;
    }

    .hero-text h1 {
        text-align: center;
        font-weight: normal;
        letter-spacing: 3px;
        font-size: 3.5rem;
        line-height: 3rem;

    }
    
    .hero-text h2 {
        text-align: center;
    }

    .hero-text { 
        text-align: center;
    }

    .heroIcons {
        justify-content: center;
      }
      
      .hero-text {
        width: 100%;
        margin: 1rem 0 0 0;
        padding: 0 1rem;
      }
}

@media screen and (max-width: 600px) {
  .hero-text h1 {
    font-size: 2.5rem;
    line-height: 2rem;
    letter-spacing: 0px;
}

.hero-text h2 {
    letter-spacing: 1px;
    font-size: 1rem;
}

.hero-text p {
  font-size: 14px;
}

.image-slider {
  height: 70dvh;
}

}