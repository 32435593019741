@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.staffingSolution {
    width: 100%;
    height: 80vh;
    background: rgba(0, 0, 0, 0.322);
    position: relative;
  }
  
  .staffingSolution:before {
    content: '';
    position: absolute;
    background: url('../../../assets/specialties_behavioral_health.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  

.staffingSolution {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.staffingSolutionContent {
    display: block;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
    width: 50%;
}

.staffingSolution h1 {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    color: rgba(255, 255, 255, 0.75);
    text-align: center;
}

.staffingSolution p {
    color: rgba(255, 255, 255, 0.75);
    text-align: center;
    text-align: center;
}

/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.staffingSolution h1 {
    font-size: 2rem;
}

.staffingSolution {
    height: 100vh;
  }

  .staffingSolutionContent {
    width: 100%;
}

}

@media screen and (max-width:700px) {
    .staffingSolution {
        height: 100vh;
      }

      .staffingSolution h1 {
        font-size: 2rem;
    }

    .staffingSolutionContent {
        bottom: 10%;
    }
}